import React, { useState } from "react";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const NavData = ["Home", "About Us", "Contact Us"];

  return (
    <>
      {/* Top Marquee */}
      {/* <div className="bg-[#28304C] py-3 topnav">
        <p className="container text-[#F8CD8C] text-center animate-marquee whitespace-nowrap max-w-full">
          Get ready for the festival and{" "}
          <span className="text-white">claim 50% off</span> on this Diwali, use code
          <span className="text-white"> DIWALI50</span> for booking property.{" "}
          <span className="invisible">empty</span> Get ready and claim 50% off on
          this Diwali, use code DIWALI50 for booking property
        </p>
      </div> */}

      {/* Navbar */}
      <div className="w-full h-[60px] flex items-center justify-center font-sans nav-bg">
        <div className="flex w-full max-w-[1440px] h-[72px] items-center justify-between px-4 md:px-[100px]">

          {/* Brand Name */}
          <div className="font-bold logo text-2xl tracking-[2.40px] logo">
            STAYOTELS
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex gap-7">
            {NavData.map((item, index) => (
              <div
                key={index}
                className="font-medium font-mohave text-base tracking-[1.20px] cursor-pointer hover:text-secondary-2 text-white"
              >
                {item.toUpperCase()}
              </div>
            ))}
          </div>

          {/* Dropdown & Hamburger Button */}
          <div className="flex items-center gap-4">
            <div
              className="hidden md:inline-flex items-center gap-2 px-4 py-2 bg-white rounded-lg cursor-pointer"
              onClick={toggleDropdown}
            >
              <span className="font-semibold font-mohave text-primary-1 text-base tracking-[1.60px]">
                HI, GUEST
              </span>
              {/* <img
                className={`w-[13.12px] h-[9.6px] transition-transform duration-300 ${
                  dropdownOpen ? "rotate-180" : "rotate-0"
                }`}
                alt="Dropdown Icon"
                src="/path-to-arrow-icon.svg"
              /> */}
            </div>

            {/* Hamburger Menu Button */}
            <button
              onClick={toggleMenu}
              className="focus:outline-none md:hidden flex items-center logo"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {menuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden w-full flex flex-col items-center py-5 nav-bg">
          {NavData.map((item, index) => (
            <div
              key={index}
              onClick={() => setMenuOpen(false)} // Close menu on click
              className="font-medium text-primary-1 text-base tracking-[1.20px] py-2 hover:text-secondary-2 text-white"
            >
              {item.toUpperCase()}
            </div>
          ))}
          <div
            onClick={toggleDropdown}
            // className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg cursor-pointer mt-4"
          >
            {/* <span className="font-medium text-primary-1 text-base tracking-[1.60px]">
              HI, GUEST
            </span>
            <img
              className={`w-[13.12px] h-[9.2px] transition-transform duration-300 ${
                dropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              alt="Dropdown Icon"
              src="/path-to-arrow-icon.svg"
            /> */}
          </div>
          
        </div>
      )}
    </>
  );
}

export default Navbar;
