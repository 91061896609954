import React from "react";
import { FaStar } from "react-icons/fa";
import { IoMdHeartEmpty } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";

const ProductCard = ({ image, name, location, details, price, rating, link }) => {
  return (
    <div
      className="relative bg-white p-1 border border-slate-100 rounded-md w-fit shadow-lg hover:shadow-xl transition-shadow"
    >
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block"
      >
        <div className="relative">
          <img
            src={image}
            className="w-[100%] h-50 object-cover rounded-t-md"
            alt={name}
          />
          <p className="w-fit rounded-xl text-sm px-2 bg-[#28304C] flex items-center gap-x-2 text-white absolute left-2 top-2">
            {rating} <FaStar />
          </p>
          <div className="bg-white rounded-full absolute right-2 top-2 p-1">
            <IoMdHeartEmpty />
          </div>
        </div>
        <div className="p-3 space-y-1">
          <p className="text-[#222222] font-semibold text-xl">{name}</p>
          <p className="text-xs text-[#5A5A5A]">{location}</p>
          {details && <p className="text-xs text-[#5A5A5A]">{details}</p>}
          <div className="flex justify-between items-center py-1">
            <p className="text-xl font-semibold text-black">
              ₹{price} <span className="text-[#5A5A5A] text-xs">+taxes</span>
            </p>
            <div className="bg-[#D9D9D980] text-[#28304C] active:opacity-50 rounded-md p-2">
              <FaArrowRight size={16} />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProductCard;
