import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ProductCard from "../Utility/Components/ProductCard";

const CardComponent = ({ categoryData }) => (
  <section className="container pb-10 py-10">
    <Splide
      options={{
        // type: "loop",
        perPage: 4,
        perMove: 1,
        gap: "2rem",
        pagination: false,
        speed: 1000,
        arrows: true,
        pauseOnHover: true,
        breakpoints: {
          1024: { perPage: 3, gap: "2rem" },
          768: { perPage: 2, gap: "1rem" },
          640: { perPage: 1, gap: "1rem" },
        },
      }}
    >
      {categoryData.map((product) => (
        <SplideSlide key={product.id}>
          <a href={product.link} target="_blank" rel="noopener noreferrer">
            <ProductCard
              image={product.image}
              name={product.name}
              location={product.location}
              price={product.price}
              rating={product.rating}
            />
          </a>
        </SplideSlide>
      ))}
    </Splide>
  </section>
);

const data = {
  Ooty: [
    { id: 1, image: "/assets/images/lablooms-ooty.png", name: "La Blooms", location:"udhakaimandalam, ooty", price: "Rs.6999/-", rating:"4.4", link:"https://landingpage.stayotels.com/" },
    { id: 2, image: "/assets/images/kottaram-ooty.png", name: "Kottaram Residecy", location:"udhakaimandalam, ooty", price: "Rs.7999/-", rating:"4.5", link:"https://landingpage.stayotels.com/" },
    { id: 3, image: "/assets/images/sinclair-ooty.png", name: "Sinclair's Retreat", location:"udhakaimandalam, ooty", price: "Rs.8999/-", rating:"4.6", link:"https://landingpage.stayotels.com/" },
    { id: 4, image: "/assets/images/poppy-ooty.png", name: "Vinayaga Poppy's", location:"udhakaimandalam, ooty", price: "Rs.8999/-", rating:"4.7", link:"https://landingpage.stayotels.com/" },
    { id: 5, image: "/assets/images/grange-ooty.png", name: "Hotel Grange", location:"udhakaimandalam, ooty", price: "Rs.9999/-", rating:"4.8", link:"https://landingpage.stayotels.com/" },
    { id: 6, image: "/assets/images/ultimate-ooty.png", name: "Hotel Ultimate", location:"udhakaimandalam, ooty", price: "Rs.7999/-", rating:"4.9", link:"https://landingpage.stayotels.com/" },
  ],
  Kodaikanal: [
    { id: 9, image: "/assets/images/lazo-kodai.png", name: "Lazo", location:"udhakai, Kodaikakanal", price: "Rs.6999/-", rating:"4.4", link:"https://landingpage.stayotels.com/"  },
    { id: 10, image: "/assets/images/poppy-kodai.png", name: "Pleasant Poppy's", location:"udhakai, Kodaikakanal", price: "Rs.7999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 11, image: "/assets/images/aarem-kodai.png", name: "Aarem Valley", location:"udhakai, Kodaikakanal", price: "Rs.8999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
  ],
  Chikmagalur: [
    { id: 17, image: "/assets/images/card2.png", name: "Ooty super Villa", location:"udhakaimandalam,ooty", price: "Rs.6999/-", rating:"4.4", link:"https://landingpage.stayotels.com/"  },
    { id: 18, image: "/assets/images/card2.png", name: "Lablooms", location:"udhakaimandalam,ooty", price: "Rs.7999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 19, image: "/assets/images/card4.png", name: "central residency", location:"udhakaimandalam,ooty", price: "Rs.8999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
    { id: 20, image: "/assets/images/card4.png", name: "kluney monar", location:"udhakaimandalam,ooty", price: "Rs.8999/-", rating:"4.7", link:"https://landingpage.stayotels.com/"  },
  ],
  Coorg: [
    { id: 21, image: "/assets/images/venkateshwara-coorg.png", name: "Venkateshwara", location:"udhakaimandalam,ooty", price: "Rs.9999/-", rating:"4.8", link:"https://landingpage.stayotels.com/"  },
    { id: 22, image: "/assets/images/hillcrist-coorg.png", name: "Hill Crist", location:"udhakaimandalam,ooty", price: "Rs.7999/-", rating:"4.9", link:"https://landingpage.stayotels.com/"  },
    { id: 23, image: "/assets/images/cliffedge-coorg.png", name: "Cliff Edge", location:"udhakaimandalam,ooty", price: "Rs.8999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 24, image: "/assets/images/coorgpalace-coorg.png", name: "Coorg Palace", location:"udhakaimandalam,ooty", price: "Rs.9999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
    { id: 25, image: "/assets/images/magnifiq-coorg.png", name: "Magnify", location:"udhakaimandalam,ooty", price: "Rs.9999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  }
  ],
  Wayanad: [
    { id: 26, image: "/assets/images/skyway-wayand.png", name: "Skyway", location:"udhakaimandalam,ooty", price: "Rs.6999/-", rating:"4.4", link:"https://landingpage.stayotels.com/"  },
    { id: 27, image: "/assets/images/greegate-wayanad.png", name: "Green Gates", location:"udhakaimandalam,ooty", price: "Rs.7999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 28, image: "/assets/images/lesapphaire-wayand.png", name: "Le Sapphaire", location:"udhakaimandalam,ooty", price: "Rs.8999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
    { id: 29, image: "/assets/images/goldencoffee-wayanad.png", name: "Golden Coffee", location:"udhakaimandalam,ooty", price: "Rs.8999/-", rating:"4.7", link:"https://landingpage.stayotels.com/"  },
  ],
  Alleppey: [
    { id: 30, image: "/assets/images/2bhk-alleppey.png", name: "2 Bedroom Boathouse", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.8", link:"https://landingpage.stayotels.com/"  },
    { id: 31, image: "/assets/images/3bhk-alleppey.png", name: "3 Bedroom Boathouse", location:"Alleppey, Kerala", price: "Rs.7999/-", rating:"4.9", link:"https://landingpage.stayotels.com/"  },
    { id: 32, image: "/assets/images/4bhk-alleppey.png", name: "4 Bedroom Boathouse", location:"Alleppey, Kerala", price: "Rs.8999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 33, image: "/assets/images/5bhk-alleppey.png", name: "5 Bedroom Boathouse", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
  ],
  Vagamon: [
    { id: 34, image: "/assets/images/sapphaire-vagamon.png", name: "Sapphire Mansion", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.8", link:"https://landingpage.stayotels.com/"  },
    { id: 35, image: "/assets/images/vagashore-vagamon.png", name: "Vagamon Vagashore", location:"Alleppey, Kerala", price: "Rs.7999/-", rating:"4.9", link:"https://landingpage.stayotels.com/"  },
    { id: 36, image: "/assets/images/chilax-vagamon.png", name: "Chilax Vagamon", location:"Alleppey, Kerala", price: "Rs.8999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 37, image: "/assets/images/exotica-vagamon.png", name: "vagamon Exotica", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
    { id: 38, image: "/assets/images/wintervale-vagamon.png", name: " Winter Vale Green Stay", location:"Alleppey, Kerala", price: "Rs.8999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 39, image: "/assets/images/coppercastle-vagamon.png", name: "  Vaga Copper Castle", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
  ],
  Munnar: [
    { id: 40, image: "/assets/images/view-munnar.png", name: "The View Munnar", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.8", link:"https://landingpage.stayotels.com/"  },
    { id: 41, image: "/assets/images/misty-munnar.png", name: "Misty Lake Resort", location:"Alleppey, Kerala", price: "Rs.7999/-", rating:"4.9", link:"https://landingpage.stayotels.com/"  },
    { id: 42, image: "/assets/images/spice-munnar.png", name: "Spice Jungle", location:"Alleppey, Kerala", price: "Rs.8999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
    { id: 43, image: "/assets/images/mistygarden-munnar.png", name: "Misty Garden", location:"Alleppey, Kerala", price: "Rs.9999/-", rating:"4.6", link:"https://landingpage.stayotels.com/"  },
    { id: 44, image: "/assets/images/Madhumanthran-munnar.png", name: "Madhumanthra Resort", location:"Alleppey, Kerala", price: "Rs.8999/-", rating:"4.5", link:"https://landingpage.stayotels.com/"  },
  ]
};

const tabs = [
  { label: "Ooty" },
  { label: "Kodaikanal" },
  { label: "Chikmagalur" },
  { label: "Coorg" },
  { label: "Wayanad" },
  { label: "Munnar" },
  { label: "Alleppey" },
  { label: "Vagamon" },
];

const TopProperties = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleOnClick = (index) => setCurrentTabIndex(index);

  return (
    <div className="bg-[#F0F0F0] py-16">
      <div className="container mx-auto px-4">
        <div className="flex max-md:flex-col md:items-center justify-between w-full">
          <div className="space-y-2">
            <p className="text-2xl md:text-3xl text-[#2E3A62] font-semibold font-playfair-display">
              Top Recommended Properties
            </p>
            <div className="pt-5">
              {/* Responsive Tabs */}
              <div className="flex md:flex-wrap gap-8 md:gap-x-8 border-b border-[#CACACA] overflow-x-auto">
                {tabs.map((tab, index) => (
                  <div
                    key={tab.label}
                    className={`pb-2 ${
                      currentTabIndex === index
                        ? "border-b-2 border-[#2E3A62]"
                        : ""
                    }`}
                    onClick={() => handleOnClick(index)}
                  >
                    <span
                      className={`cursor-pointer text-lg capitalize ${
                        currentTabIndex === index
                          ? "text-[#2E3A62] font-medium"
                          : "text-[#5A5A5A]"
                      }`}
                    >
                      {tab.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container mx-auto px-4">
        <CardComponent categoryData={data[tabs[currentTabIndex].label] || []} />
      </div>
    </div>
  );
};

export default TopProperties;