import React, { useState, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css"; // Import Splide CSS
import { LuArrowRight } from "react-icons/lu";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const banner = [
  {
    id: 1,
    image: "/assets/images/hero-banner.png",
    content: "Plan Your Dream Holiday With HiStay",
  },
  {
    id: 2,
    image: "/assets/images/hero-banner.png",
    content: "Plan Your Dream Holiday With HiStay",
  },
  {
    id: 3,
    image: "/assets/images/hero-banner.png",
    content: "",
  },
  {
    id: 4,
    image: "/assets/images/hero-banner.png",
    content: "",
  },
];

const BannerSlider = () => {
  const splideRef = useRef(null); // Reference for Splide instance
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide

  const handleSlideChange = (splide) => {
    setCurrentSlide(splide.index); // Update current slide on move
  };

  return (
    <div className="relative w-full">
      {/* Splide slider component */}
      <Splide
        ref={splideRef}
        options={{
          type: banner.length > 1 ? "loop" : "slide",
          perPage: 1,
          perMove: 1,
          autoplay: false,
          interval: 3000,
          gap: "0rem",
          pagination: false, // Hide default pagination
          speed: 1000,
          arrows: false, // Hide default arrows
          easing: "linear",
        }}
        onMoved={handleSlideChange} // Handle slide change using Splide event
        onMounted={(splide) => {
          // Set the current slide index when the component mounts
          setCurrentSlide(splide.index);
        }}
      >
        {banner?.map((item, index) => (
          <SplideSlide className="relative" key={index}>
            <div
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "calc(100vh - 4rem)", // Adjust for navbar height
              }}
              className="flex flex-col justify-center items-center space-y-5 text-center pb-10"
            >
              <p className="text-white text-4xl sm:text-4xl md:text-6xl leading-normal xl:leading-normal font-playfair-display pb-8">
                <span className="block md:inline-block">Plan Your </span>{" "}
                <span className="block md:inline-block">Dream</span>{" "}
                <span className="block md:inline-block">Holiday</span>{" "}
                <span className="block ">With{" "}<span className="block md:inline-block">Stayotels</span></span>
                
              </p>
              <button className="cursor-pointer border text-center border-white text-white flex items-center justify-center gap-x-2 whitespace-nowrap py-2 px-5 rounded-md">
                Book Now
                <LuArrowRight className="w-4 h-4 mt-1 text-white" />
              </button>
            </div>
          </SplideSlide>
        ))}
      </Splide>
      {/* Custom Pagination - Positioned at the bottom center */}
      <div className="absolute bottom-28 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {banner?.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full ${
              currentSlide === index
                ? "border border-slate-400 bg-white"
                : "border border-slate-400"
            }`}
            onClick={() => splideRef.current?.splide?.go(index)} // Safely access splide instance
          >
            <span className="sr-only">Go to slide {index + 1}</span>
          </button>
        ))}
      </div>

      {/* Custom Arrow Buttons */}
      <div className="absolute inset-0 flex justify-between items-center px-5 md:px-10 lg:px-20">
        <button
          className="border p-2 border-white rounded-md transition-all"
          onClick={() => splideRef.current?.splide?.go("<")} // Safely access splide instance
        >
          <MdKeyboardArrowLeft size={16} className="text-white" />
        </button>
        <button
          className="border p-2 border-white rounded-md transition-all"
          onClick={() => splideRef.current?.splide?.go(">")} // Safely access splide instance
        >
          <MdKeyboardArrowRight size={16} className="text-white" />
        </button>
      </div>
    </div>
  );
};

export default BannerSlider;
