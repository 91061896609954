// import React, { useRef } from "react";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import "@splidejs/react-splide/css";
// import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";

// const data = [
//   { id: 1, name: "Ooty", image: "/assets/images/ootydestination.png" },
//   { id: 2, name: "Kodaikanal", image: "/assets/images/kodaikanaldestination.png" },
//   { id: 3, name: "Valparai", image: "/assets/images/valparaidestination.png" },
//   { id: 4, name: "Chikmagalur", image: "/assets/images/chikmagalurdestination.png" },
//   { id: 5, name: "Coorg", image: "/assets/images/coorgdestination.png" },
//   { id: 6, name: "Wayanad", image: "/assets/images/wayanaddestination.png" },
// ];

// const FavoriteLocation = () => {
//   const categorySplide = useRef(null);

//   const prevCategory = () => {
//     if (categorySplide.current) {
//       categorySplide.current.go("-1");
//     }
//   };

//   const nextCategory = () => {
//     if (categorySplide.current) {
//       categorySplide.current.go("+1");
//     }
//   };

//   return (
//     <div className="bg-white pt-20 md:pt-20 pb-20">
//       <div className="container mx-auto px-4">
//         <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-5">
//           <div className="text-center md:text-left">
//             <h2 className="text-2xl md:text-3xl font-semibold font-playfair-display text-[#2E3A62]">
//               Discover Your Favourite Location
//             </h2>
//             <p className="text-l md:text-xl text-[#5A5A5A] mt-3">
//               We can assist you with your innovation and commercialisation journey!
//             </p>
//           </div>
//           <div className="flex gap-3">
//             <button
//               className="text-[#28304C] p-3 rounded-full hover:bg-[#28304C] hover:text-white"
//               onClick={prevCategory}
//             >
//               <FaAngleLeft size={20} />
//             </button>
//             <button
//               className="text-[#28304C] p-3 rounded-full hover:bg-[#28304C] hover:text-white"
//               onClick={nextCategory}
//             >
//               <FaAngleRight size={20} />
//             </button>
//           </div>
//         </div>
//       </div>

//       <section className="container mx-auto px-4 py-10">
//         <Splide
//           ref={categorySplide}
//           options={{
//             type: "loop",
//             perPage: 6, // Desktop
//             perMove: 1,
//             autoplay: true,
//             interval: 3000,
//             gap: "1.5rem",
//             pagination: false,
//             arrows: false,
//             pauseOnHover: true,
//             breakpoints: {
//               1024: { perPage: 4, gap: "1rem" }, // Tablet
//               768: { perPage: 2, gap: "0.75rem" }, // Mobile (small)
//               480: { perPage: 2, gap: "0.5rem" }, // Mobile (very small)
//             },
//           }}
//         >
//           {data.map((category, index) => (
//             <SplideSlide key={index}>
//               <CategoryCard category={category} />
//             </SplideSlide>
//           ))}
//         </Splide>
//       </section>
//     </div>
//   );
// };

// export default FavoriteLocation;

// const CategoryCard = ({ category }) => {
//   return (
//     <div
//       className="relative flex justify-center items-center rounded-lg overflow-hidden shadow-lg"
//       style={{
//         backgroundImage: `url(${category.image})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         width: "100%",
//         height: "160px",
//         maxWidth: "160px",
//         borderRadius: "40px",
//       }}
//     >
//       {/* Darker overlay for better text contrast */}
//       <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent rounded-lg"></div>

//       {/* Text Styling */}
//       <p className="text-white text-xl md:text-2xl font-bold z-10">
//         {category.name}
//       </p>
//     </div>
//   );
// };




import React, { useRef } from "react";

const FavoriteLocation = () => {

  return (
          <div className="container mx-auto mb-24 mt-10">
            <div className="row popular_section d-flex aligns-items-center justify-content-center">
              <div className="space-y-2 py-5 md:py-10 mb-8" >
                <p className="text-2xl md:text-3xl text-[#2E3A62] font-playfair-display font-semibold text-center">
                  Most Favourite and Recommended Locations
                </p>,
                <p className="text-l md:text-xl text-[#5A5A5A] text-center">
                  Discover Your Favourite Location, Discover Your Favourite Location,
                </p>
              </div>
              <div className="location_img_container">
                <div className="single_location">
                  <a href="/OotyPackages">
                    <img src="/assets/images/ootydestination.png" alt="" className="img-fluid" />  </a>
                    <div className="location_name">
                      <p>Ooty</p> 
                      <span>Tamilnadu</span>
                  </div>
                </div>
                <div className="single_location">
                  <a href="https://kodaikanal2nights3days.bookonetour.in">
                    <img src="/assets/images/kodaikanaldestination.png" alt="" className="img-fluid" /> </a>
                    <div className="location_name">
                      <p>Kodaikanal</p>
                      <span>Tamilnadu</span>
                  </div>
                </div>
                <div className="single_location">
                <a href="https://coorg2nights3days.bookonetour.in">
                  <img src="/assets/images/coorgdestination.png" alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Coorg</p>
                    <span>Karnataka</span>
                  </div>
                </div>
                <div className="single_location">
                <a href="https://chikmagalur2nights3days.bookonetour.in">
                  <img src="/assets/images/chikmagalurdestination.png" alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Chikmagalur</p>
                    <span>Karnataka</span>
                  </div>
                </div>
                <div className="single_location">
                <a href="https://munnar2nights3days.bookonetour.in">
                  <img src="/assets/images/munnardestination.png" alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Munnar</p>
                    <span>Keral</span>
                  </div>
                </div>
                <div className="single_location">
                <a href="https://wayanad2nights3days.bookonetour.in">
                  <img src="/assets/images/wayanaddestination.png" alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Wayanad</p>
                    <span>Kerala</span>
                  </div>
                </div>
                <div className="single_location">
                  <a href="https://alleppey2nights3days.bookonetour.in">
                  <img src="/assets/images/ootydestination.png" alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Alleppey</p>
                    <span>Kerala</span>
                  </div>
                </div>
                <div className="single_location">
                <a href="https://vagamon2nights3days.bookonetour.in">
                  <img src="/assets/images/vagamondestination.png" alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Vagamon</p>
                    <span>Kerala</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
};

export default FavoriteLocation;
